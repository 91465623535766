import React, { useRef, useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./statistics.less"
const Statistics = () => {
  const [totalSupply, setTotalSupply] = useState(0)
  const [circulatingSupply, setCirculatingSupply] = useState(0)
  const [stakeReturn, setStakeReturn] = useState(0)
  const timerId = useRef(0)

  const parseHtml = data => {
    let parser = new DOMParser()
    let doc = parser.parseFromString(data, "text/html")
    let value = doc.getElementById("value").innerHTML
    return value
  }

  const fetchValues = () => {
    fetch("https://explorer.petrachor.com/totalSupply/dec")
      .then(response => response.text())
      .then(data => setTotalSupply(parseHtml(data)))
    fetch("https://explorer.petrachor.com/circulatingSupply/dec")
      .then(response => response.text())
      .then(data => setCirculatingSupply(parseHtml(data)))
    fetch("https://explorer.petrachor.com/returns/")
      .then(response => response.text())
      .then(data => setStakeReturn(parseHtml(data)))
  }

  useEffect(() => {
    fetchValues()
    timerId.current = setInterval(() => {
      fetchValues()
    }, 60000)
    return () => {
      clearInterval(timerId.current)
    }
  }, [])
  
  return (
    <div className="statistics">
      <Container>
        <Row className="mb-5">
          <h5 className="h1">Real-Time Statistics</h5>
        </Row>
        <Row>
          <Col md={4}>
            <h4 className="h6">Total Supply</h4>
            <p className="number h4">{parseFloat(totalSupply).toLocaleString()}</p>
          </Col>
          <Col md={4}>
            <h4 className="h6">Circulating Supply</h4>
            <p className="number h4">{parseFloat(circulatingSupply).toLocaleString()}</p>
          </Col>
          <Col md={4}>
            <h4 className="h6">Staking Return</h4>
            <p className="number h4">{stakeReturn}</p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="d-flex justify-content-center">
            <a className="btn explorer" href="https://explorer.petrachor.com/">Explorer</a>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Statistics
