import React from "react"
import { Col, Container, Row, Image } from "react-bootstrap"
import "./technical-overview.less"
import { useStaticQuery ,graphql} from "gatsby"
const TechnicalOverview = props => {
  const data=useStaticQuery(graphql`
  query TechnicalOverviewQuery {
    strapiHomepage {
      TechnicalOverviewTitle
      TechnicalOverviewDesc
      TechnicalOverviewCard1 {
        id
        CardTitle
        CardDescription
        CardLogo {
          localFile{
            publicURL
          }
        }
      }
      TechnicalOverviewCard2 {
        id
        CardTitle
        CardDescription
        CardLogo {
          localFile{
            publicURL
          }
        }
      }
      TechnicalOverviewCard3 {
        id
        CardTitle
        CardDescription
        CardLogo {
          localFile{
            publicURL
          }
        }
      }
    }
  }
  `);
  return (
    <div className="technical-overview-container pb-5">
      <Container>
        <Row>
          <Col sm={12} className="text-center">
            <h3 id="ariel-protocol-consensus" className="mb-5 mt-5 h1">{data.strapiHomepage.TechnicalOverviewTitle} </h3>
            <p className="mb-5 mt-5">
              {data.strapiHomepage.TechnicalOverviewDesc}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={4} className="p-2">
            <div className="proof">
              <Image src={data.strapiHomepage.TechnicalOverviewCard1.CardLogo.localFile.publicURL} className="overview-icon" />
              <h3 className="text-uppercase h5">{data.strapiHomepage.TechnicalOverviewCard1.CardTitle}</h3>
              <p>
                {data.strapiHomepage.TechnicalOverviewCard1.CardDescription}
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} className="p-2">
            <div className="scheme">
              <Image src={data.strapiHomepage.TechnicalOverviewCard2.CardLogo.localFile.publicURL} className="overview-icon"></Image>
              <h3 className="text-uppercase h5">{data.strapiHomepage.TechnicalOverviewCard2.CardTitle}</h3>
              <p>
              {data.strapiHomepage.TechnicalOverviewCard2.CardDescription}
              </p>
            </div>
          </Col>
          <Col md={12} lg={4} className="p-2">
            <div className="elliptic">
              <Image src={data.strapiHomepage.TechnicalOverviewCard3.CardLogo.localFile.publicURL} className="overview-icon"></Image>
              <h3 className="text-uppercase h5">{data.strapiHomepage.TechnicalOverviewCard3.CardTitle}</h3>
              <p>
              {data.strapiHomepage.TechnicalOverviewCard3.CardDescription}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TechnicalOverview
