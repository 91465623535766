import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Mockup from "../../../images/homepage-main@2x.png"
import "./home-jumbotron.less"
const HomeJumbotron = props => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      allStrapiHomepage {
        nodes {
          MainDescription
          MainTitle
        }
      }
    }
  `)
  return (
    <Container className="home-jumbotron-container">
      <Row>
        <Col className="d-flex flex-column" sm={4}>
          <h1 className="display-2">{data.allStrapiHomepage.nodes[0].MainTitle}</h1>
          <p className="h5">{data.allStrapiHomepage.nodes[0].MainDescription}</p>
          <a className="btn  launch-button" href="https://app.petrachor.com/">Launch App</a>
        </Col>
        <Col sm={8}>
          <Image src={Mockup} className="mockup-image" fluid></Image>
        </Col>
      </Row>
    </Container>
  )
}

export default HomeJumbotron
