import React from 'react'
import { Image } from 'react-bootstrap'
import FileIcon from "../../../images/references-card-icon-file.svg"
import ArrowIcon from "../../../images/references-card-icon-arrow.svg"
import "./references-box.less";
const ReferenceBox = (props) => {
    return (
        <div className="reference-box">
            <h4 className="text-uppercase h4">{props.title}</h4>
            <a href={props.link} className="link"><Image src={ArrowIcon}/></a>
            <Image src={FileIcon} className="file-image"></Image>
        </div>
    )
}

export default ReferenceBox
