import React from "react"
import { Container, Col, Row, Image } from "react-bootstrap"
import {useStaticQuery,graphql} from "gatsby"
import ReactMarkdown from "react-markdown"
import Image1 from "../../../images/proof-of-stake-1.jpg"
import Image2 from "../../../images/proof-of-stake-2.png"
import "./proof-of-stake.less"
const ProofOfStake = props => {
  const data=useStaticQuery(graphql`
  query proofQuery {
    allStrapiHomepage {
      nodes {
        ProofOfStakeFirstPart
        ProofOfStakeSecondPart
      }
    }
  }
  `);
  return (
    <div className="proof-of-stake-container">
      <Container>
        <Row>
          <Col md={6} sm={{ order: 2 }} className="d-flex flex-column justify-content-center">
            <h2 className="h1" id="proof-of-stake">Proof of Stake V4</h2>
            <ReactMarkdown>{data.allStrapiHomepage.nodes[0].ProofOfStakeFirstPart}</ReactMarkdown>
          </Col>
          <Col md={6} sm={{ order: 1 }}>
            <Image src={Image1} fluid></Image>
          </Col>
          <Col md={6} sm={{ order: 3 }}>
          <ReactMarkdown>{data.allStrapiHomepage.nodes[0].ProofOfStakeSecondPart}</ReactMarkdown>
          </Col>
          <Col md={6} sm={{ order: 4 }} className="d-flex flex-column justify-content-center">
            <Image className="skewed" src={Image2} fluid></Image>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ProofOfStake
