import React from 'react'
import {Row, Col, Container, Image} from 'react-bootstrap'
import {useStaticQuery,graphql} from "gatsby";
import "./description.less"
const Description = (props) => {

  const data=useStaticQuery(graphql`
  query DescriptionQuery {
    strapiHomepage {
      DescriptionCards {
        id
        Description
        Image {
          localFile{
          publicURL
          }
        }
      }
    }
  }
  `)

    return (
        <div>
            <Container>
                <Row className="d-flex flex-row justify-content-center">
                    {data.strapiHomepage?.DescriptionCards?.map((item,index)=>{
                        return(
                            <Col md={6} lg={3} key={item.id} className="block"><Image src={item.Image?.localFile.publicURL}/><p className="description">{item.Description}</p></Col>
                        )
                    })}
                </Row>
            </Container>
        </div>
    )
}

export default Description