import React from "react"
import { Col, Container, Row, Image } from "react-bootstrap"
import "./specifications.less"
import { useStaticQuery, graphql } from "gatsby"
import SpecificationBg from "../../../images/specifications-bg-logo.png"
import WhiteDot from "../../../images/blur-ellipse-blue.png"
import RedDot from "../../../images/blur-ellipse-orange.png"
import MockUp from "../../../images/specifications-mockup@2x.png"
const Specifications = props => {
  const data = useStaticQuery(graphql`
    query SpecQuery {
      allStrapiHomepage {
        nodes {
          Specs {
            Description
            Title
            id
          }
          SpecificationTitle
        }
      }
    }
  `)
  const specArray1 = data.allStrapiHomepage.nodes[0].Specs?.slice(0, 6)
  const specArray2 = data.allStrapiHomepage.nodes[0].Specs?.slice(6)
  return (
    <div className="specifications-container">
      <h4 id="stake-delegation-rewards" className="text-center h1">{data.allStrapiHomepage.nodes[0].SpecificationTitle}</h4>
      <Image className="logo" src={SpecificationBg}></Image>
      <Image className="white-dot" src={WhiteDot}></Image>
      <Image className="red-dot" src={RedDot}></Image>
      <Container>
        <Row>
          <Col sm={3}>
            {specArray1.map((item, index) => {
              return (
                <div key={item.id} className="spec-box">
                  <p className="title">{item.Title}</p>
                  <p className="desc">{item.Description}</p>
                </div>
              )
            })}
          </Col>
          <Col sm={3}>
            {specArray2.map((item, index) => {
              return (
                <div key={item.id} className="spec-box">
                  <p className="title">{item.Title}</p>
                  <p className="desc">{item.Description}</p>
                </div>
              )
            })}
          </Col>
          <Col sm={6}>
            <div className="mockup-container">
              <Image fluid className="mockup" src={MockUp}></Image>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Specifications
