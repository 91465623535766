import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ReferenceBox from "./referenceBox"
import {useStaticQuery,graphql} from "gatsby"
import "./references.less"
const References = (props) => {
  const data = useStaticQuery(graphql`
  query MyQuery {
    strapiHomepage {
      ReferencesTitle
      References {
        Header
        Link
        id
      }
    }
  }
  
  `)
  return (
    <div className="references-section">
      <Container>
        <h4 className="text-center h1">{data.strapiHomepage.ReferencesTitle}</h4>
        <Row className="d-flex flex-row justify-content-around align-items-center flew-wrap">
          {data.strapiHomepage.References.map((item,index)=>{
            return(
              <ReferenceBox key={item.id} title={item.Header} link={item.Link} />
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default References
