import React from "react"
import { Col, Container, Row, Button, Image } from "react-bootstrap"
import { useStaticQuery, graphql ,Link} from "gatsby"
import FooterLogo from "../../images/footer-white-logo-petrachor.svg"
import "./footer.less"
const Footer = props => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      strapiHomepage {
        FooterEmail
        FooterLinks {
          Link
          Title
          id
        }
        FooterSocials {
          Link
          id
          IconImage {
            localFile{
            publicURL
            }
          }
        }
      }
    }
  `)

  const links1 = data.strapiHomepage?.FooterLinks?.slice(0, 4)
  const links2 = data.strapiHomepage?.FooterLinks?.slice(4, 10)
  const links3 = data.strapiHomepage?.FooterLinks?.slice(10)
 
  return (
    <div className="footer-section">
      <Container>
        <Row>
          <Col md={2}>
            <ul className="list-group">
              <li className="list-group-item px-0"><Image src={FooterLogo} className="w-100"></Image></li>
              <li className="list-group-item"><p className="copyright my-2">Copyright © 2021 Petrachor. All rights reserved.</p></li>
            </ul>
            
            
          </Col>
          {/* <Col md={2}>
            <ul className="list-group">
              {links1?.map((item, index) => {
                let weight= index==0 ? "bold":"normal"
                return (
                  <li key={item.id} style={{fontWeight:weight}} className="list-group-item">
                    <a href={item.Link}>{item.Title}</a>
                  </li>
                )
              })}
            </ul>
          </Col>
          <Col md={3}>
            <ul className="list-group">
              {links2?.map((item, index) => {
                let weight= index==0 ? "bold":"normal"
                return (
                  <li key={item.id} style={{fontWeight:weight}} className="list-group-item">
                    <a href={item.Link}>{item.Title}</a>
                  </li>
                )
              })}
            </ul>
          </Col>
          <Col md={3}>
            <ul className="list-group">
              {links3?.map((item, index) => {
                let weight= index==0 ? "bold":"normal"
                return (
                  <li key={item.id} style={{fontWeight:weight}} className="list-group-item">
                    <a href={item.Link}>{item.Title}</a>
                  </li>
                )
              })}
            </ul>
          </Col> */}
          <Col md={1}></Col>
          <Col md={3}>
            <ul className="list-group">
              <li className="list-group-item" style={{fontWeight:'bold'}}>
                <a href={`/`}>Home</a>
              </li>
              <li className="list-group-item">
                <a href={`/contactus/`}>Contact Us</a>
              </li>
              <li className="list-group-item">
                <a href="https://explorer.petrachor.com">Explorer</a>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <ul className="list-group">
              <li className="list-group-item" style={{fontWeight:'bold'}}>
                <a>Technology</a>
              </li>
              <li className="list-group-item" >
                <a href={`/#proof-of-stake`}>Proof of Stake</a>
              </li>
              <li className="list-group-item">
                <a href={`/#ariel-protocol-consensus`}>Ariel Protocol Consensus</a>
              </li>
              <li className="list-group-item">
                <a href={`/#stake-delegation-rewards`}>Stake Delegation/Rewards</a>
              </li>
            </ul>
          </Col>
          <Col md={2} className="text-center">
            <a href="https://app.petrachor.com/" className="btn download my-2">Launch App</a>
            <div className="d-flex flex-row justify-content-between my-2">
              {data.strapiHomepage.FooterSocials?.map((item, index) => {
                return (
                  <a key={item.id} href={item.Link} className="social">
                    <Image src={item.IconImage.localFile.publicURL}></Image>
                  </a>
                )
              })}
            </div>
            <p className="email my-2 h6">{data.strapiHomepage?.FooterEmail}</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
