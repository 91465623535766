import React from "react"
import BackgroundContainer from "../components/homepage/backgroundContainer/backgroundContainer"
import HomeJumbotron from "../components/homepage/homeJumbotron/homeJumbotron"
import Navbar from "../components/navbar/navigationBar"
import Description from "../components/homepage/description/description"
import ProofOfStake from "../components/homepage/proofOfStake/proofOfStake"
import TechnicalOverview from "../components/homepage/technicalOverview/technicalOverview"
import Statistics from "../components/homepage/statistics/statistics"
import Downloads from "../components/homepage/downloads/downloads"
import References from "../components/homepage/references/references"
import Footer from "../components/footer/footer"
import Specifications from "../components/homepage/specification/specifications"
const homePage = props => {
  return (
    <div>
        <BackgroundContainer>
            <Navbar/>
            <HomeJumbotron/>
            <Description />
        </BackgroundContainer>
        <ProofOfStake />
        <TechnicalOverview/>
        <Specifications />
        <Statistics/>
        <Downloads />
        <References />
        <Footer/>
    </div>
  )
}

export default homePage
