import React from 'react'
import "./background-container.css"
const BackgroundContainer = (props) => {
    return (
        <div className="background">
            {props.children}
        </div>
    )
}

export default BackgroundContainer