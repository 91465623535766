import React from "react"
import { Button, Container, Row, Image } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Windows from "../../../images/windows.svg"
import MacOs from "../../../images/macos.svg"
import Android from "../../../images/android.svg"
import Linux from "../../../images/linux.svg"
import Ios from "../../../images/ios.svg"
import "./downloads.less"
const Downloads = () => {
  const data = useStaticQuery(graphql`
    query DownloadInfo {
      strapiHomepage {
        DownloadDescription
        DownloadTitle
      }
    }
  `)
  return (
    <div className="downloads-container">
      <Container>
        <Row className="text-center">
          <h2 className="h1">{data.strapiHomepage.DownloadTitle}</h2>
          <p className="h5">{data.strapiHomepage.DownloadDescription}</p>
        </Row>
        <Row className="d-flex flex-row justify-content-around align-items-center flew-wrap text-center">
          <a href="https://app.petrachor.com/" className="btn windows">
            <Image src={Windows} />
            <div className="d-inline-block mx-2">Windows</div>
          </a>
          <a href="https://app.petrachor.com/" className="btn macos">
            <Image src={MacOs} />
            <div className="d-inline-block mx-2">macOS</div>
          </a>
          <a href="https://app.petrachor.com/" className="btn linux">
            <Image src={Linux} />
            <div className="d-inline-block mx-2">Linux</div>
          </a>
          <a href="https://app.petrachor.com/" className="btn ios">
            <Image src={Ios} />
            <div className="d-inline-block mx-2">iOS</div>
          </a>
          <a href="https://app.petrachor.com/" className="btn android">
            <Image src={Android} />
            <div className="d-inline-block mx-2">Android</div>
          </a>
        </Row>
      </Container>
    </div>
  )
}

export default Downloads
